// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// docBaseUrl need to be updated with prodction which will be used for reading document
export const environment = {
  production: false,
  baseUrl: 'https://go.coguide.in/c1/',
  authUrl: 'https://go.coguide.in/',
  profileUrl: 'https://go.coguide.in/api/v1/user/',
  anaylsisUrl: 'https://go.coguide.in/api/v1/analysis/',
  anaylsisAiUrl: 'https://ai.coguide.in/ai_analysis/',
  documentUrl: 'https://go.coguide.in/api/v1/doc/',
  docBaseUrl:'https://reapv2.coguide.in/',
  rmsBaseUrl:'https://rms.coguide.in/',
  // baseUrl: 'https://dev.coguide.in/c1/',
  // authUrl: 'https://dev.coguide.in/',
  // profileUrl: 'https://dev.coguide.in/api/v1/user/',
  // anaylsisUrl: 'https://dev.coguide.in/api/v1/analysis/',
  // anaylsisAiUrl: 'https://ai.coguide.in/ai_analysis/',
  // documentUrl: 'https://dev.coguide.in/api/v1/doc/',
  // docBaseUrl:'https://reapdev.coguide.in/',
  // rmsBaseUrl:'https://rms.coguide.in/',
  // baseUrl: 'https://test.coguide.in/c1/',
  // authUrl: 'https://test.coguide.in/',
  // profileUrl: 'https://test.coguide.in/api/v1/user/',
  // anaylsisUrl: 'https://test.coguide.in/api/v1/analysis/'
//   baseUrl: 'http://localhost:9070/c1/',
//   authUrl: 'http://localhost:9070/',
//   profileUrl: 'http://localhost:9070/api/v1/user/',
//   anaylsisUrl: 'https://localhost:9070/api/v1/analysis/',
//   anaylsisAiUrl: 'https://ai.coguide.in/ai_analysis/',
//   documentUrl: 'https://localhost:9070/api/v1/doc/',
// docBaseUrl:'https://localhost:9070/',
// rmsBaseUrl:'https://rms.coguide.in/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
